// 销售订单-退单，输入框的历史数据匹配建议

import * as API_saleCard from "@/api/saleCard";

const mixins = {
  data() {
    return {
      clientList: [],
      agentList: [],
      linkNameList: [],
      phoneList: [],
      addressList: [],
      salesNameList: [],
      makeOrderList: []
    }
  },
  methods: {
    returnMatchSuggestion(type) {
      let newList;
      API_saleCard.getUserInputHistory(type).then(res => {
        newList = res.map(item => {
          return { value: item }
        })
        switch (type) {
          case 'CLIENT':
            this.clientList = newList;
            break;
          case 'AGENCY':
            this.agentList = newList;
            break;
          case 'LINKMAN':
            this.linkNameList = newList;
            break;
          case 'PHONE':
            this.phoneList = newList;
            break;
          case 'ADDRESS':
            this.addressList = newList;
            break;
          case 'SALESMAN':
            this.salesNameList = newList;
            break;
          case 'WRIGHT':
            this.makeOrderList = newList;
            break;
        }
      });
    },
    clientScreen(queryString, cb) {
      let result = this.clientList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    agentScreen(queryString, cb) {
      let result = this.agentList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    linkNameScreen(queryString, cb) {
      let result = this.linkNameList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    phoneScreen(queryString, cb) {
      let result = this.phoneList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    addressScreen(queryString, cb) {
      let result = this.addressList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    salesManScreen(queryString, cb) {
      let result = this.salesNameList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    makeOrderScreen(queryString, cb) {
      let result = this.makeOrderList.filter((item) => {
        return item.value.toLowerCase().includes(queryString.toLowerCase())
      })
      cb(result)
    },
    // select方法
    phoneSelect(item) {
      this.formInfo.phone = item.value;
      this.handlePhoneChange(item.value)
    }
  }
}

export default mixins;
