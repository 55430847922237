<template>
  <div class="page">
    <el-form :model="formInfo" label-width="120px" :rules="formRules" ref="formInfo">
      <el-card>
        <template slot="header">
          <span class="title">卡券信息</span>
          <div class="button-back">
            <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
              <img src="@/assets/back.png" alt="">
            </el-button>
          </div>
        </template>
        <div class="card-body">
          <el-form-item label="关联销售单号" prop="sell_trade_sn" :error="noMatchError">
            <el-input
              v-model="formInfo.sell_trade_sn"
              size="small"
              style="width: 200px;"
              placeholder="请输入关联销售单号" @blur="handleSellerSnBlur"
              clearable />
          </el-form-item>
          <el-form-item label="所属商城" prop="shop_id" :error="errorShopId">
            <el-select v-model="formInfo.shop_id" size="small" style="width: 200px;" placeholder="请选择商城" clearable @change="handleShopChange">
              <el-option v-for="(item, index) in shopList" :label="item.shop_name" :value="item.shop_id" :key="index" />
            </el-select>
          </el-form-item>
          <el-form-item label="录入卡号">
            <div class="left">
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="textarea"
                rows="10"
                size="small"
                @focus="textareaFocus"
                @input="textareaInput"
              />
            </div>
            <div class="right">
              <input style="display: none;" type="file" ref="excel-upload-input" accept=".xlsx, .xls, .csv" @change="handleClick" />
              <el-button size="small" type="primary" @click="handleUpload">导入</el-button>
              <el-upload action="" style="line-height: 15px;">
                <div slot="tip" class="el-upload__tip">
                  提示：导入的文件仅支持xls、xlxs、csv格式的Excel文件。请保证上传的Excel文件中所有卡号均在第一列，且每个单元格中只有1个卡号。
                </div>
              </el-upload>
              <div style="margin-top: 12%">
                <el-button type="primary" size="small" @click="handleExportCardConfirm">确定</el-button>
                <el-button type="primary" size="small" @click="textarea = '';">重置</el-button>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="卡券信息" class="card-info">
            <table border>
              <tr>
                <th width="15%">卡券名称</th>
                <th width="200px">面值</th>
                <th width="30%">卡号段</th>
                <th width="200px">数量</th>
                <th width="180px">折扣率(%)</th>
                <th width="200px">折扣金额</th>
                <th width="230px">优惠后金额</th>
                <th width="300px">操作</th>
              </tr>
              <tr v-for="(item, index) in formInfo.cardList" :key="index">
                <td class="card-name">
                  <el-select
                    v-model="item.card_id"
                    filterable style="width: 100%;"
                    size="small"
                    @blur="cardNameBlur($event, item, index)"
                  >
                    <el-option
                      v-for="(item, index) in shopCardList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </td>
                <td>
                  <span>
                    <el-input-number
                      style="width: 100%;"
                      size="small"
                      :controls="false"
                      :min="0.00"
                      :precision="2"
                      v-model="item.card_value"
                      disabled
                    />
                  </span>
                </td>
                <td class="card-section">
                  <div v-for="(sec, sec_index) in item.card_range_list" :key="sec_index" style="display: flex; align-items: center; margin-bottom: 4px;">
                    <el-input
                      size="small"
                      v-model="sec.card_code_start"
                      placeholder="卡号起始"
                      @blur="handleEditCardRange(item, index, sec_index)"
                      ref="cardCodeStart"
                    /> -
                    <el-input
                      size="small"
                      v-model="sec.card_code_end"
                      placeholder="卡号结束"
                      @blur="handleEditCardRange(item, index, sec_index)"
                    />
                    <el-button
                      class="icon-btn"
                      icon="el-icon-circle-plus-outline"
                      circle @click="addCardSection(item.card_range_list)"></el-button>
                    <el-button
                      class="icon-btn"
                      icon="el-icon-remove-outline"
                      circle
                      :disabled="item.card_range_list.length === 1"
                      @click="delCardSection(item, sec_index, index)"
                    ></el-button>
                  </div>
                </td>
                <td>
                  <el-input-number
                    style="width: 100%;"
                    :controls="false"
                    :min="0"
                    :precision="0"
                    size="small"
                    v-model.number="item.card_num"
                    @blur="handleEditCardNum(item, index)"
                  ></el-input-number>
                </td>
                <td>
                  <el-input-number
                    style="width: 100%"
                    :controls="false"
                    :min="0.00"
                    :max="100.00"
                    :precision="2"
                    size="small"
                    v-model="item.discount_ratio"
                    disabled
                  ></el-input-number>
                </td>
                <td>
                  <el-input-number
                    style="width: 100%"
                    :controls="false"
                    :min="0"
                    :precision="2"
                    size="small"
                    v-model="item.discount_value"
                    disabled
                  ></el-input-number>
                </td>
                <td>
                  <el-input-number
                    style="width: 100%"
                    :min="0"
                    :controls="false"
                    :precision="2"
                    size="small"
                    v-model="item.money"
                    disabled
                  ></el-input-number>
                </td>
                <td>
                  <el-button type="primary" size="small" @click="addRows">添加</el-button>
                  <el-button type="danger" size="small" :disabled="formInfo.cardList.length === 1" @click="delRows(index)">删除</el-button>
                </td>
              </tr>
            </table>
          </el-form-item>
          <el-row style="width: 55%;">
            <el-col :span="4">
              <el-form-item label="合计总金额">
                <el-input size="small" style="width: 200px;" v-model="formInfo.total_price" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4" :offset="11">
              <el-form-item label="优惠后总金额">
                <el-input size="small" style="width: 200px;" v-model="formInfo.after_discount_price" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <template slot="header">
          <span class="title">基础信息</span>
        </template>
        <div class="base-info">
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="客户名称" prop="client_name">
                <el-autocomplete
                  style="width: 100%"
                  :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('CLIENT')"
                  :fetch-suggestions="clientScreen"
                  size="small"
                  v-model="formInfo.client_name"
                  clearable
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="代理名称">
                <el-autocomplete
                  style="width: 100%"
                  :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('AGENCY')"
                  :fetch-suggestions="agentScreen"
                  size="small"
                  v-model="formInfo.agent_name"
                  clearable></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="联系人名称">
                <el-autocomplete
                  style="width: 100%"
                  :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('LINKMAN')"
                  :fetch-suggestions="linkNameScreen"
                  size="small"
                  v-model="formInfo.link_name"
                  clearable />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="6">
              <el-form-item label="联系电话" :error="phoneError">
                <el-autocomplete
                  style="width: 100%"
                  :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('PHONE')"
                  :fetch-suggestions="phoneScreen"
                  size="small"
                  @change="handlePhoneChange"
                  @select="phoneSelect"
                  v-model="formInfo.link_phone"
                  clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="销售人员" prop="sell_name">
                <el-autocomplete
                  style="width: 100%"
                  :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('SALESMAN')"
                  :fetch-suggestions="salesManScreen"
                  size="small"
                  v-model="formInfo.sell_name"
                  clearable />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="制单人名称">
                <el-autocomplete
                  style="width: 100%"
                  :trigger-on-focus="false"
                  @focus="returnMatchSuggestion('WRIGHT')"
                  :fetch-suggestions="makeOrderScreen"
                  size="small"
                  v-model="formInfo.make_order_name"
                  clearable />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="客户地址">
            <el-autocomplete
              style="width: 100%"
              :trigger-on-focus="false"
              @focus="returnMatchSuggestion('ADDRESS')"
              :fetch-suggestions="addressScreen"
              size="small"
              v-model="formInfo.client_addr"
              clearable />
          </el-form-item>
          <el-form-item label="备注信息">
            <el-input size="small" type="textarea" placeholder="请输入200字以内的备注信息" v-model="formInfo.remark" rows="5" />
          </el-form-item>
          <el-form-item align="center" style="width: 100%;">
            <el-button type="primary" @click="isPermission" :disabled="saveDisabled">保存</el-button>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
  </div>
</template>

<script>
import XLSX from "xlsx";
import * as API_saleCard from "@/api/saleCard";
import mixins from './saleOrderRefundMixins/index'
import * as API_Order from '@/api/order';
import {asyncRouterMap} from "@/router";

export default {
  name: "addSaleRefundRecord",
  mixins: [mixins],
  data() {
    return {
      // K20220105000001
      noMatchError: '',
      isEdit: !!this.$route.params.id,
      id: this.$route.params.id,
      originalData: [],
      formInfo: {
        sell_trade_sn: '',
        shop_id: '',
        shop_name: '',
        total_price: '',
        after_discount_price: '',
        client_name: '',
        client_addr: '',
        sell_name: '',
        link_name: '',
        link_phone: '',
        agent_name: '',
        remark: '',
        make_order_name: '',
        cardList: [
          {
            card_name: '',
            card_value: '',
            card_range_list: [
              {
                card_code_start: '',
                card_code_end: ''
              }
            ],
            card_num: '',
            discount_ratio: '',
            discount_value: '',
            money: ''
          }
        ]
      },
      formRules: {
        sell_trade_sn: [
          { required: true, message: '请输入关联销售单号', trigger: 'blur' }
        ],
        shop_id: [
          { required: true, message: '请选择所属商城', trigger: 'blur' }
        ],
        client_name: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        sell_name: [
          { required: true, message: '请输入销售人员名称', trigger: 'blur' }
        ]
      },
      textarea: '',
      shopList: [],
      shopCardList: [],
      phoneError: '',
      saveDisabled: true,
      originalShopId: '',
      errorShopId: ''
    }
  },
  mounted() {
    if (this.isEdit) {
      API_saleCard.getSaleRefundDetail(this.id).then(res => {
        for (let i in this.formInfo) {
          if (res.refund_record_sell_do[i]) {
            this.formInfo[i] = res.refund_record_sell_do[i]
          }
        }
        this.formInfo.cardList = res.refund_sell_card_dos.reduce((pre, cur) => {
          if (pre.length) {
            let matchIndex = 0;
            if (pre.some((item, index) => { matchIndex = index; return item.card === cur.card_id })) {
              pre[matchIndex].card_num += cur.card_num;
              const total = cur.card_value * pre[matchIndex]['card_num'];
              pre[matchIndex]['card_range_list'].push({
                card_code_start: cur.card_code_start,
                card_code_end: cur.card_code_end,
              });
              pre[matchIndex]['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
              pre[matchIndex]['money'] = total - pre[matchIndex]['discount_value']
            } else {
              let obj = {}
              const total = cur.card_value * cur.card_num
              obj['card_name'] = cur.card_name
              obj['card_value'] = cur.card_value
              obj['card_num'] = cur.card_num
              obj['discount_ratio'] = cur.discount_ratio
              obj['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
              obj['money'] = total - obj['discount_value']
              obj['shop_id'] = cur.shop_id
              obj['card_id'] = cur.card_id
              obj['card_range_list'] = [
                {
                  card_code_start: cur.card_code_start,
                  card_code_end: cur.card_code_end,
                }
              ]
              pre.push(obj)
            }
          } else {
            let obj = {}
            const total = cur.card_value * cur.card_num
            obj['card_name'] = cur.card_name
            obj['card_value'] = cur.card_value
            obj['card_num'] = cur.card_num
            obj['discount_ratio'] = cur.discount_ratio
            obj['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
            obj['money'] = total - obj['discount_value']
            obj['shop_id'] = cur.shop_id
            obj['card_id'] = cur.card_id
            obj['card_range_list'] = [
              {
                card_code_start: cur.card_code_start,
                card_code_end: cur.card_code_end,
              }
            ]
            pre.push(obj)
          }
          return pre;
        }, [])
        this.getShopCardList(this.formInfo.shop_id);
        this.originalShopId = this.formInfo.shop_id;
        this.saveDisabled = false;
      })
    }
    API_saleCard.getShopList({mall_type: 2}).then(res => {
      this.shopList = res
    })
  },
  methods: {
    resetForm() {
      this.formInfo = {
        sell_trade_sn: '',
        shop_id: '',
        shop_name: '',
        total_price: '',
        after_discount_price: '',
        client_name: '',
        client_addr: '',
        sell_name: '',
        link_name: '',
        link_phone: '',
        agent_name: '',
        remark: '',
        make_order_name: '',
        cardList: [
          {
            card_name: '',
            card_value: '',
            card_range_list: [
              {
                card_code_start: '',
                card_code_end: ''
              }
            ],
            card_num: '',
            discount_ratio: '',
            discount_value: '',
            money: ''
          }
        ]
      }
    },
    handleSellerSnBlur(val) {
      this.saveDisabled = true;
      if (val.target.value) {
        this.noMatchError = '';
        API_saleCard.getSnSaleOrderDetail(val.target.value).then(res => {
          this.resetForm();
          if (!res.trade_sell_card_dos.length) {
            this.noMatchError = '请输入正确的关联销售单号';
            return false;
          }
          this.noMatchError = '';
          for (let i in this.formInfo) {
            if (res.trade_sell_do[i]) {
              this.formInfo[i] = res.trade_sell_do[i]
            }
          }
          this.formInfo.cardList = res.trade_sell_card_dos.reduce((pre, cur) => {
            if (pre.length) {
              let matchIndex = 0;
              if (pre.some((item, index) => { matchIndex = index; return item.card === cur.card_id })) {
                pre[matchIndex].card_num += cur.card_num;
                const total = cur.card_value * pre[matchIndex]['card_num'];
                pre[matchIndex]['card_range_list'].push({
                  card_code_start: cur.card_code_start,
                  card_code_end: cur.card_code_end,
                });
                pre[matchIndex]['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
                pre[matchIndex]['money'] = total - pre[matchIndex]['discount_value']
              } else {
                let obj = {}
                const total = cur.card_value * cur.card_num
                obj['card_name'] = cur.card_name
                obj['card_value'] = cur.card_value
                obj['card_num'] = cur.card_num
                obj['discount_ratio'] = cur.discount_ratio
                obj['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
                obj['money'] = total - obj['discount_value']
                obj['shop_id'] = cur.shop_id
                obj['card_id'] = cur.card_id
                obj['card_range_list'] = [
                  {
                    card_code_start: cur.card_code_start,
                    card_code_end: cur.card_code_end,
                  }
                ]
                pre.push(obj)
              }
            } else {
              let obj = {}
              const total = cur.card_value * cur.card_num
              obj['card_name'] = cur.card_name
              obj['card_value'] = cur.card_value
              obj['card_num'] = cur.card_num
              obj['discount_ratio'] = cur.discount_ratio
              obj['discount_value'] = (total * (1 - (cur.discount_ratio / 100)))
              obj['money'] = total - obj['discount_value']
              obj['shop_id'] = cur.shop_id
              obj['card_id'] = cur.card_id
              obj['card_range_list'] = [
                {
                  card_code_start: cur.card_code_start,
                  card_code_end: cur.card_code_end,
                }
              ]
              pre.push(obj)
            }
            return pre;
          }, [])
          // this.formInfo.after_discount_price = '';
          // this.formInfo.total_price = '';
          this.totalPriceAndDiscountPrice();
          this.formInfo['sell_trade_sn'] = res.trade_sell_do.trade_sn;
          this.getShopCardList(this.formInfo.shop_id);
          this.originalShopId = this.formInfo.shop_id
        })
      }
    },
    handlePhoneChange(val) {
      const phoneReg = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/
      if (phoneReg.test(val)) {
        this.phoneError = '';
        this.saveDisabled = false;
      } else if (val && !phoneReg.test(val)) {
        this.phoneError = '手机号格式错误';
        this.saveDisabled = true;
      } else {
        this.phoneError = '';
        this.saveDisabled = false;
      }
    },
    addCardSection(item) {
      const obj = {
        card_code_start: '',
        card_code_end: ''
      }
      item.push(obj)
    },
    delCardSection(item, sec_index, index) {
      item.card_range_list.splice(sec_index, 1);
      if (sec_index === item.card_range_list.length) {
        sec_index -= 1
      }
      this.handleEditCardRange(item, index, sec_index)
    },
    addRows() {
      const obj = {
        card_name: '',
        card_value: 10,
        card_range_list: [
          {
            card_code_start: '',
            card_code_end: ''
          }
        ],
        card_num: '',
        discount_ratio: '',
        discount_value: '',
        money: ''
      }
      this.formInfo.cardList.push(obj)
    },
    delRows(index) {
      this.formInfo.cardList.splice(index, 1);
      if (index === this.formInfo.cardList.length) {
        index -= 1
      }
      this.handleEditCardRange(this.formInfo.cardList[index], index, 0)
    },
    // 获取商城的卡券列表
    getShopCardList(val) {
      API_saleCard.getShopCardList(val).then(res => {
        this.shopCardList = res.map(item => {
          return {
            value: item.id,
            label: item.card_name
          }
        })
      })
    },
    // 商城下拉列表change事件
    handleShopChange(val) {
      this.formInfo.cardList = [{
        card_name: '',
        card_value: '',
        card_range_list: [
          {
            card_code_start: '',
            card_code_end: ''
          }
        ],
        card_num: '',
        discount_ratio: '',
        discount_value: '',
        money: ''
      }];
      this.formInfo.total_price = 0;
      this.formInfo.after_discount_price = 0;
      if (val) {
        if (this.originalShopId && this.originalShopId !== val) {
          this.errorShopId = '所选商城与关联销售单号不匹配';
          this.saveDisabled = true;
        } else {
          this.errorShopId = '';
          this.saveDisabled = false;
        }
        this.formInfo.shop_name = this.shopList.filter(item => {
          return item.shop_id === val
        })[0].shop_name
        API_saleCard.getShopCardList(val).then(res => {
          this.shopCardList = res.map(item => {
            return {
              value: item.id,
              label: item.card_name
            }
          })
        })
      }
    },
    // 卡券名称blur事件
    cardNameBlur($event, item, index) {
      setTimeout(() => {
        const value = $event.target.value;
        const divError = document.createElement('div')
        divError.className = 'el-form-item__error'
        divError.innerText = '请选择卡券';
        if (!value) {
          this.saveDisabled = true;
          $event.path[0].style = 'border-color: red;';
          if ($event.path[2].lastChild.className !== 'el-form-item__error') {
            $event.path[2].appendChild(divError)
          }
        } else {
          this.saveDisabled = false;
          item.card_name = this.shopCardList.filter(i => {
            if (i.value === item.card_id) {
              return item
            }
          })[0].label
          $event.path[0].style = 'border-color: #DCDFE6;'
          if ($event.path[2].lastChild.className === 'el-form-item__error') {
            $event.path[2].removeChild($event.path[2].lastChild)
          }
          if (item.card_value) {
            this.handleEditCardRange(item, index, 0)
          }
        }
      }, 100);
    },
    // 导入方法
    textareaFocus() {
      if (!this.formInfo.shop_id) {
        this.$message.error('请先选择所属商城');
        return;
      }
      if (!this.formInfo.sell_trade_sn) {
        this.$message.error('请输入关联销售单号');
      }
    },
    textareaInput() {
      if (!this.formInfo.shop_id) {
        this.$message.error('请先选择所属商城');
        return;
      }
      if (!this.formInfo.sell_trade_sn) {
        this.$message.error('请输入关联销售单号');
      }
    },
    handleUpload() {
      if (this.formInfo.shop_id && this.formInfo.sell_trade_sn) {
        this.$refs['excel-upload-input'].click();
        return;
      }
      if (!this.formInfo.shop_id) {
        this.$message.error('请先选择所属商城');
        return
      }
      if (!this.formInfo.sell_trade_sn) {
        this.$message.error('请输入关联销售单号');
      }
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile)
    },
    upload(rawFile) {
      this.$refs['excel-upload-input'].value = null; // fix can't select the same excel
      this.readerData(rawFile);
    },
    readerData(rawFile) {
      let _this = this
      let cardList = ''
      const filename = rawFile.name
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;
          const fixedData = this.fixData(data);
          const workbook = XLSX.read(btoa(fixedData), {type: 'base64'});
          const wsname = workbook.SheetNames[0];//取第一张表
          const ws = XLSX.utils.sheet_to_formulae(workbook.Sheets[wsname]);//生成json表格内容
          if (!ws.length) return
          _this.originalData = ws.map(item => {
            if (!/^A/.test(item)) {
              this.$message.error('导入的表格格式不正确，请重新选择文件')
              return;
            }
            return item.split("'")[1]
          })
          cardList = _this.originalData.map(item => {
            return _this.stringLength(item)
          })
          _this.textarea = cardList.join('\n')
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    fixData(data) {
      let o = '';
      let l = 0;
      const w = 10240;
      for (; l < data.byteLength / w; ++l) {
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      }
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
    // 判断字符串占的长度
    stringLength(str) {
      let strLen = 0;
      let newStr = ''
      for (let i = 0; i < str.length; i++) {
        if (strLen > 19) {
          newStr += '...'
          break;
        } else {
          if (str.charCodeAt(i) > 255) { //如果是汉字，则字符串长度加2
            strLen += 2;
          } else {
            strLen++;
          }
          newStr += str[i]
        }
      }
      return newStr;
    },
    // 确定按钮触发事件
    handleExportCardConfirm() {
      this.saveDisabled = true;
      this.originalData = this.textarea.split('\n').filter(item => {if (item) return item;})
      if (!this.originalData.length) {
        this.$message.error('卡号信息为空，请选择导入或输入再继续操作!')
        return false;
      }
      let params = {
        shop_id: this.formInfo.shop_id,
        card_code_list: this.originalData,
        sell_trade_sn: this.formInfo.sell_trade_sn
      }
      API_saleCard.saleRefundAutoIdentify(params).then(res => {
        let total = 0;
        res.forEach(item => {
          total = item.card_num * item.card_value
          item['discount_ratio'] = item.discount_ratio
          item['discount_value'] = total - (total * (item.discount_ratio / 100))
          item['money'] = total - item['discount_value']
          item['originalCardNum'] = item.card_num
        })
        this.formInfo.cardList = res;
        this.totalPriceAndDiscountPrice()
        this.originalData = [];
        this.saveDisabled = false;
      }).catch(() => {
        this.originalData = []
        this.formInfo.cardList = [
          {
            card_name: '',
            card_value: '',
            card_range_list: [
              {
                card_code_start: '',
                card_code_end: ''
              }
            ],
            card_num: 0,
            discount_ratio: '',
            discount_value: '',
            money: ''
          }
        ]
        this.formInfo.total_price = 0
        this.formInfo.after_discount_price = 0
      })
    },
    // 编辑卡号段
    handleEditCardRange(item, index, childIndex) {
      this.saveDisabled = true;
      for(let i=0;i<item.card_range_list.length;i++) {
        if (!item.card_range_list[i].card_code_start || !item.card_range_list[i].card_code_end) {
          this.$message.error('卡号区间的起始、结束输入框为必填项')
          return false;
        }
      }
      if (item.card_range_list[childIndex].card_code_start && item.card_range_list[childIndex].card_code_end) {
        item['shop_id'] = this.formInfo.shop_id
        item['card_range_index'] = childIndex
        item['sell_trade_sn'] = this.formInfo.sell_trade_sn
        API_saleCard.editRefundCardRange(item).then(res => {
          this.saveDisabled = false;
          if (res.active_card_range.length && (res.disable_card_range.length || res.binding_card_range.length || res.inactive_card_range.length)) {
            const h = this.$createElement;
            let bingingCard = res.binding_card_range.length ? res.binding_card_range.map(item => {
              if (item.card_code_start === item.card_code_end) {
                return item.card_code_start
              } else {
                return `${item.card_code_start}-${item.card_code_end}`
              }
            }) : '';
            let inActiveCard = res.inactive_card_range.length ? res.inactive_card_range.map(item => {
                if (item.card_code_start === item.card_code_end) {
                  return item.card_code_start
                } else {
                  return `${item.card_code_start}-${item.card_code_end}`
                }
              }) : '';
            this.$msgbox({
              title: '提示',
              message: h('div', null, [
                h('p', null, '当前卡号区间存在已绑定/未激活的卡号，是否需要由系统自动将卡号区间拆分成多个卡号区间'),
                h('span', { style: 'color: red;' }, `(已绑定的卡号/未激活:${bingingCard ? bingingCard.join(',') : ''}${inActiveCard ? inActiveCard.join(',') : ''})`)
              ]),
              showCancelButton: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(() => {
              if (res.active_card_range.length) {
                item.card_range_list.splice(item.card_range_index, 1)
                item.card_range_list.splice(item.card_range_index, 0, ...res.active_card_range);
                // item.card_range_list = res.active_card_range;
              } else {
                item.card_range_list.splice(item.card_range_index, 1);
              }
              item.card_num = res.inactive_card_number;
              item['originalCardNum'] = item.card_num
              this.handleEditCardNum(item, index)
            }).catch(() => {
              this.$set(item.card_range_list, childIndex, {card_code_start: '', card_code_end: ''})
              this.$refs.cardCodeStart[0].focus();
            })
          } else if (res.active_card_range.length) {
            item.card_id = res.card_id
            item.card_name = res.card_name
            item.card_value = res.card_value
            item.card_num = res.inactive_card_number
            item.discount_value = res.discount_value
            item['originalCardNum'] = res.card_num
            this.handleEditCardNum(item, index)
          } else {
            this.$message.error('没有可用的卡号，请重新填写区间')
            item.card_range_list[childIndex].card_code_start = ''
            item.card_range_list[childIndex].card_code_end = '';
            this.saveDisabled = true;
          }
        }).catch(() => {
          let obj = {
            card_id: item.card_id,
            card_name: item.card_name,
            card_value: '',
            card_range_list: [
              {
                card_code_start: '',
                card_code_end: ''
              }
            ],
            card_num: 0,
            discount_ratio: '',
            discount_value: '',
            money: ''
          }
          this.$set(this.formInfo.cardList, index, obj);
          this.totalPriceAndDiscountPrice();
        })
      } else {
        this.$message.error('卡号区间的起始、结束输入框为必填项');
      }
    },
    // 编辑卡号数量
    handleEditCardNum(item, index) {
      this.saveDisabled = true;
      item.shop_id = this.formInfo.shop_id
      item.sell_trade_sn = this.formInfo.sell_trade_sn
      if (!item.card_num) {
        this.$message.error('请输入卡号数量');
        item.card_num = item.originalCardNum;
        return false;
      }
      if (item.card_range_list[0].card_code_start && item.card_range_list[0].card_code_end) {
        item.card_range_list.forEach((cardItem, cardIndex) => {
          if (!cardItem.card_code_start || !cardItem.card_code_end) {
            item.card_range_list.splice(cardIndex, 1)
          }
        })
        const _params = {
          trade_sell_sn: this.formInfo.sell_trade_sn,
          card_info_list: [item]
        }
        API_saleCard.editRefundCardNum(_params).then(res => {
          res.forEach(i => {
            let total = i.card_value * i.card_num
            i['discount_value'] = (total * (1 - (i.discount_ratio / 100)))
            i['money'] = total - i.discount_value
            i['originalCardNum'] = i.card_num
          })
          this.$set(this.formInfo.cardList, index, res[0])
          this.totalPriceAndDiscountPrice();
          this.saveDisabled = false;
        }).catch(() => {
          item.card_num = item.originalCardNum;
        })
      }
    },
    // 折扣率，折扣金额，优惠金额blur
    handleDiscountAndMoney(type, item) {
      if (!item.card_value) {
        this.$message.error('请先导入卡号')
        return;
      }
      const total = item.card_num * item.card_value
      if (type === 'discount_ratio') { // 折扣率
        item.discount_value = total * (1 - (item.discount_ratio / 100))
        item.money = total - item.discount_value
      } else if (type === 'discount_value') { // 折扣金额
        if (item.discount_value > total) {
          this.$message.error('折扣金额最大只能到面值*数量所对应的金额')
          item.discount_value = total
          item.discount_ratio = 100
          item.money = 0
        } else {
          item.discount_ratio = ((total - item.discount_value) / total) * 100
          item.money = total - item.discount_value
        }
      } else { // 优惠金额
        if (item.money > total) {
          this.$message.error('折扣金额最大只能到面值*数量所对应的金额')
          item.money = total
          item.discount_value = 0
          item.discount_ratio = 100
        } else {
          item.discount_value = total - item.money
          item.discount_ratio = ((total - item.discount_value) / total) * 100
        }
      }
      this.totalPriceAndDiscountPrice()
    },
    // 计算总金额和优惠后总金额
    totalPriceAndDiscountPrice() {
      this.formInfo.total_price = 0
      this.formInfo.after_discount_price = 0
      this.formInfo.cardList.forEach(i => {
        let total = i.card_num * i.card_value
        this.formInfo.total_price += total
        this.formInfo.after_discount_price += parseFloat((i.money ? i.money : 0).toFixed(2))
      })
    },
    // 判断是否有销售开卡权限
    isPermission() {
      API_Order.getShopExtAuth().then(res => {
        if (res.open === 'OPEN') {
          this.save();
        } else {
          this.$message.error('平台已关闭销售开卡功能，如有其他问题，请联系平台。');
          setTimeout(() => {
            this.handleNoPermission(asyncRouterMap);
          }, 2000)
        }
      })
    },
    // 保存
    save() {
      const params = {
        card_info_list: this.formInfo.cardList,
        ...this.formInfo
      }
      delete params['cardList']
      params['shop_name'] = this.shopList.filter(item => {
        return item.shop_id === params.shop_id
      })[0].shop_name
      this.$refs.formInfo.validate((validate) => {
        if (validate) {
          if (this.isEdit) {
            API_saleCard.updateSaleRefundRecord(params, this.id).then(res => {
              this.$message.success('修改成功');
              this.$router.push({name: 'saleCardOpenManagement'})
            }).catch((err) => {
              if (err.response.data.code !== '301') {
                this.$router.go(-1);
              }
            })
          } else {
            params['card_num'] = this.formInfo.cardList.reduce((pre, cur) => {
              pre += cur.card_num;
              return pre;
            }, 0)
            API_saleCard.addSaleRefundRecord(params).then(res => {
              this.$message.success('添加成功');
              this.$router.push({name: 'saleCardOpenManagement'})
            }).catch((err) => {
              if (err.response.data.code !== '301') {
                this.$router.go(-1);
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  .el-card:nth-child(1) {
    /deep/ .el-card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 20px;
      .button-back {
        height: 40px;
        .back_btn {
          width: 36px;
          height: 36px;
          background-color: #1A43A9;
          border-radius: 50%;
          img {
            width: 10px;
            height: 20px;
            position: relative;
            top: -2px;
            left: -4px;
          }
        }
      }
    }
    /deep/ .el-form-item__content {
      table {
        border: 1px solid #E6ECF7;
        tr > th {
          text-align: center;
          background-color: #E6ECF7;
        }
        tr > td {
          padding: 10px 5px;
          text-align: center;
          input {
            width: 100%;
          }
          .el-input__inner {
            padding: 0 5px;
          }
        }
        tr {
          .card-name, .card-section {
            min-width: 200px;
          }
          .icon-btn {
            border: 0;
            padding: 0;
            margin-left: 0;
            .el-icon-circle-plus-outline, .el-icon-remove-outline {
              font-size: 22px;
            }
          }
        }
      }
    }
    .card-info {
      /deep/ .el-form-item__label {
        display: inline-block !important;
        float: none !important;
      }
      /deep/ .el-form-item__content {
        margin-left: 55px !important;
      }
    }
  }
  .el-card {
    /deep/ .el-card__header {
      .title:before {
        content: " ";
        display: inline-block;
        position: relative;
        left: -20px;
        width: 5px;
        height: 20px;
        background: #1A43A9;
        transform: translate(0, 20%);
      }
    }
    margin-bottom: 20px;
    .card-body {
      .el-form-item {
        .el-form-item__content {
          .left {
            width: 200px;
          }
          .right {
            position: absolute;
            top: 0;
            left: 240px;
          }
        }
      }
    }
  }
}
</style>
